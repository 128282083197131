import { useQuery } from 'react-apollo';
import { useCartContext } from '../context/CartContext';
import { ESTIMATE_SHIPPING_METHODS } from '../queries/cart';

const useEstimateShippingMethods = () => {
  const { cart } = useCartContext();
  const address = cart && cart.shipping_addresses[0];

  const queryResult = useQuery(ESTIMATE_SHIPPING_METHODS, {
    ssr: false,
    fetchPolicy: 'cache-and-network',
    variables: {
      address: address && {
        countryId: address.country.code,
        postcode: address.postcode,
        city: address.city,
      },
    },
  });

  return {
    shippingMethods: queryResult.data && queryResult.data.estimateShippingMethods,
    ...queryResult,
  };
};

export default useEstimateShippingMethods;
